export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '5365045534674740',
  agentpwd : '5365@4740$',
  apikey : '285db1426e7a0ab49a1b604ad168d1d9',
  url : 'https://senorav.afrikpay.com',
  id : 'senorav.afrikpay.com',
  accepturl : 'https://senorav.afrikpay.com/payment/web/success',
  cancelurl : 'https://senorav.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afrikpay.com/print/",
  storage : 'home/oss'
};
